@keyframes App-logo-spin {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(360deg)
    }
}

.App {
    text-align: center
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 25vmin;
    max-height: 320px;
    max-width: 320px;
    pointer-events: none;
    border-radius: 50%
}

@media only screen and (max-width:600px) {
    .App-logo {
        height: 65vmin
    }

    .name {
        font-size: 2rem
    }

    .external img {
        height: 14vmin
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #fff
}

.App-link {
    color: #61dafb
}

.flex-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between
}

a+a {
    margin-left: 10px
}
